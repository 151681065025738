// Report types as per version 1
export enum ReportType {
  INCOME_STATEMENT = 'income-statement',
  ACTIVITY = 'activity',
  REALISED = 'realised',
  REALISED_SUMMARY = 'realised-summary',
  UNREALISED = 'unrealised',
  UNREALISED_SUMMARY = 'unrealised-summary',
  PORTFOLIO_VALUATION = 'portfolio-valuation'
}
export enum BaseReportType {
  REALISED = 'realised',
  UNREALISED = 'unrealised',
  INCOME_EX_DATE = 'income-by-ex-date',
  INCOME_PAY_DATE = 'income-by-pay-date',
  INCOME_TAX_YEAR = 'income-by-tax-year'
}

// Other types
export const incomeExtractMap = {
  [BaseReportType.INCOME_EX_DATE]: 0,
  [BaseReportType.INCOME_PAY_DATE]: 1,
  [BaseReportType.INCOME_TAX_YEAR]: 2
};

export interface ReportParams {
  startDate: Date | string;
  endDate: Date | string;
  subPortfolioId?: number;
  securityId?: number | string;
}
export interface IBaseReportParams {
  startDate?: Date | string;
  endDate?: Date | string;
  subPortfolioId: number;
}

// Report types as per version 2
export enum ReportCategory {
  UNREALISED = 'unrealised',
  REALISED = 'realised',
  ACTIVITY = 'activity',
  INCOME = 'income',
  COST_CHANGES = 'cost-changes',
  REGISTRY_DATA = 'registry-data',
  CGT_SCHEDULE = 'cgt-schedule',
  BASE_REPORT = 'base-report',
  BASE_REPORTS = 'base-reports' // TO be discarded in future
}

export enum UnrealisedReport {
  DETAIL = 'unrealised-detail',
  SUMMARY = 'unrealised-summary',
  NET_OFF_DETAIL = 'unrealised-net-off-detail',
  NET_OFF_SUMMARY = 'unrealised-net-off-summary',
  PORTFOLIO_VALUATION = 'portfolio-valuation',
  PORTFOLIO_SUMMARY = 'portfolio-summary',
  COST_RECON_DETAIL = 'unrealised-cost-recon-detail',
  COST_RECON_SUMMARY = 'unrealised-cost-recon-summary',
  LOWER_COST_MKT_VALUE_DETAIL = 'lower-cost-market-value-detail',
  LOWER_COST_MKT_VALUE_SUMMARY = 'lower-cost-market-value-summary'
}

export enum RealisedReport {
  DETAIL = 'realised-detail',
  SUMMARY = 'realised-summary',
  NET_OFF_DETAIL = 'realised-net-off-detail',
  NET_OFF_SUMMARY = 'realised-net-off-summary',
  COST_RECON_DETAIL = 'realised-cost-recon-detail',
  COST_RECON_SUMMARY = 'realised-cost-recon-summary'
}

export enum CgtScheduleReport {
  CGT_SCHEDULE_DETAIL = 'cgt-schedule-detail',
  CGT_SCHEDULE_SUMMARY = 'cgt-schedule-summary',
  CGT_SCHEDULE_FORM = 'cgt-schedule-form'
}

export enum ActivityReport {
  TRANSACTION = 'transaction-report'
}

export enum IncomeReport {
  COMPANY = 'company-income',
  TRUST = 'trust-income',
  INCOME_BY_PAY_DATE = 'income-by-pay-date'
  // BASE_REPORT_BY_TAX_YEAR = 'base-report-by-tax-year'
}

export enum CostChangesReport {
  SUMMARY = 'cost-change-summary',
  DETAIL = 'cost-change-detail'
}

export enum RegistryDataReport {
  HOLDING = 'holding-information',
  REGISTRY_QTY_RECON = 'registry-qty-recon'
}

export enum BaseReport {
  UNREALISED = 'unrealised',
  REALISED = 'realised',
  UNREALISED_NET_OFF = 'unrealised-net-off',
  REALISED_NET_OFF = 'realised-net-off',
  UNREALISED_COMBINED = 'unrealised-combined',
  INCOME_BY_TAX_YEAR = 'income-by-tax-year'
}

export type ReportSubCategory =
  | UnrealisedReport
  | RealisedReport
  | ActivityReport
  | IncomeReport
  | CostChangesReport
  | RegistryDataReport
  | CgtScheduleReport
  | BaseReport;

export interface ReportMenuItems {
  label: string;
  category: ReportCategory;
  items: {
    label: string;
    category: ReportCategory;
    subCategory: ReportSubCategory;
    hideInProd?: boolean;
  }[];
}

export interface ReportMenuEvent {
  category: ReportCategory;
  subCategory: ReportSubCategory;
}

export interface RealisedParams {
  startDate: string;
  endDate: string;
  showSummary?: boolean;
  dontShowCapitalGains: boolean;
  dontShowTransferOrIntraFundGain: boolean;
  // Optional
  subPortfolioId?: number;
  securityIds?: string[];
}

export enum PriceSelection {
  STM_PRICE = 0,
  USER_PRICE = 1,
  USER_PRICE_THEN_STM_PRICE = 2
}

export interface UnRealisedParams {
  // startDate: string;
  // endDate: string;
  showSummary?: boolean;
  priceDate: string;
  noOfDaysForBackwardSearch: number;
  priceSelectionType: PriceSelection;
  // Optional
  subPortfolioId?: number;
  securityIds?: string[];
}

export interface BaseReportParams {
  startDate: string;
  endDate: string;
  extractType: 0 | 1 | 2;
  // Optional
  subPortfolioId?: number;
  securityIds?: string[];
}

export interface TransactionActivityParams {
  startDate: string;
  endDate: string;
  // Optional
  subPortfolioId?: number;
  securityIds?: string[];
}

export interface IncomeParams {
  // taxYear: number;
  startDate: string;
  endDate: string;
  // Optional
  subPortfolioId?: number;
}

export interface CostChangesParams {
  startDate: string;
  endDate: string;
  // Optional
  subPortfolioId?: number;
  securityIds?: string[];
}

export interface RegistryDataParams {
  // Optional
  subPortfolioId?: number;
}

export type ReportRequestBody =
  | BaseReportParams
  | UnRealisedParams
  | RealisedParams
  | TransactionActivityParams
  | IncomeParams
  | CostChangesParams
  | RegistryDataParams;

export const IncomeExtractMapping: Record<string, number> = {
  [BaseReport.INCOME_BY_TAX_YEAR]: 2
};

export interface ISecurity {
  securityId: string;
  securityCode: string;
  securityName: string;
}
