import {
  ReportCategory,
  UnrealisedReport,
  RealisedReport,
  ActivityReport,
  IncomeReport,
  ReportMenuItems,
  CostChangesReport,
  RegistryDataReport,
  CgtScheduleReport,
  BaseReport
} from './reports.types';

export const reportMenuItems: ReportMenuItems[] = [
  {
    label: 'Unrealised',
    category: ReportCategory.UNREALISED,
    items: [
      {
        label: 'Unrealised Summary (No Net Off)',
        category: ReportCategory.UNREALISED,
        subCategory: UnrealisedReport.SUMMARY
      },
      {
        label: 'Unrealised Detail (No Net off)',
        category: ReportCategory.UNREALISED,
        subCategory: UnrealisedReport.DETAIL
      },
      {
        label: 'Unrealised Summary (Net off)',
        category: ReportCategory.UNREALISED,
        subCategory: UnrealisedReport.NET_OFF_SUMMARY
      },
      {
        label: 'Unrealised Detail (Net off)',
        category: ReportCategory.UNREALISED,
        subCategory: UnrealisedReport.NET_OFF_DETAIL
      },
      {
        label: 'Portfolio Valuation',
        category: ReportCategory.UNREALISED,
        subCategory: UnrealisedReport.PORTFOLIO_VALUATION
      },
      {
        label: 'Portfolio Summary (By Period)',
        category: ReportCategory.UNREALISED,
        subCategory: UnrealisedReport.PORTFOLIO_SUMMARY
      },
      {
        label: 'Cost Reconciliation Summary',
        category: ReportCategory.UNREALISED,
        subCategory: UnrealisedReport.COST_RECON_SUMMARY
      },
      {
        label: 'Cost Reconciliation Detail',
        category: ReportCategory.UNREALISED,
        subCategory: UnrealisedReport.COST_RECON_DETAIL
      },
      {
        label: 'Revenue Account Lower Cost/Market Value Report (Summary)',
        category: ReportCategory.UNREALISED,
        subCategory: UnrealisedReport.LOWER_COST_MKT_VALUE_SUMMARY
      },
      {
        label: 'Revenue Account Lower Cost/Market Value Report (Detail)',
        category: ReportCategory.UNREALISED,
        subCategory: UnrealisedReport.LOWER_COST_MKT_VALUE_DETAIL
      }
    ]
  },
  {
    label: 'Realised',
    category: ReportCategory.REALISED,
    items: [
      {
        label: 'Realised Summary (No Net Off)',
        category: ReportCategory.REALISED,
        subCategory: RealisedReport.SUMMARY
      },
      {
        label: 'Realised Detail (No Net off)',
        category: ReportCategory.REALISED,
        subCategory: RealisedReport.DETAIL
      },
      {
        label: 'Realised Summary (Net off)',
        category: ReportCategory.REALISED,
        subCategory: RealisedReport.NET_OFF_SUMMARY
      },
      {
        label: 'Realised Detail (Net off)',
        category: ReportCategory.REALISED,
        subCategory: RealisedReport.NET_OFF_DETAIL
      },
      {
        label: 'Cost Reconciliation Summary',
        category: ReportCategory.REALISED,
        subCategory: RealisedReport.COST_RECON_SUMMARY
      },
      {
        label: 'Cost Reconciliation Detail',
        category: ReportCategory.REALISED,
        subCategory: RealisedReport.COST_RECON_DETAIL
      }
    ]
  },
  {
    label: 'CGT Schedule',
    category: ReportCategory.CGT_SCHEDULE,
    items: [
      {
        label: 'CGT Schedule No Netoff Detail',
        category: ReportCategory.CGT_SCHEDULE,
        subCategory: CgtScheduleReport.CGT_SCHEDULE_DETAIL
      },
      {
        label: 'CGT Realised Gain/Loss Summary (No Netoff)',
        category: ReportCategory.CGT_SCHEDULE,
        subCategory: CgtScheduleReport.CGT_SCHEDULE_SUMMARY
      },
      {
        label: 'CGT Schedule Form (Netoff)',
        category: ReportCategory.CGT_SCHEDULE,
        subCategory: CgtScheduleReport.CGT_SCHEDULE_FORM
      }
    ]
  },
  {
    label: 'Activity',
    category: ReportCategory.ACTIVITY,
    items: [
      {
        label: 'Transaction Activity Report',
        category: ReportCategory.ACTIVITY,
        subCategory: ActivityReport.TRANSACTION
      }
    ]
  },
  {
    label: 'Income',
    category: ReportCategory.INCOME,
    items: [
      {
        label: 'Company Income',
        category: ReportCategory.INCOME,
        subCategory: IncomeReport.COMPANY
      },
      {
        label: 'Trust Income',
        category: ReportCategory.INCOME,
        subCategory: IncomeReport.TRUST
      },
      {
        label: 'Income By Pay Date',
        category: ReportCategory.INCOME,
        subCategory: IncomeReport.INCOME_BY_PAY_DATE
      }
    ]
  },
  {
    label: 'Cost Changes',
    category: ReportCategory.COST_CHANGES,
    items: [
      {
        label: 'Summary',
        category: ReportCategory.COST_CHANGES,
        subCategory: CostChangesReport.SUMMARY
      },
      {
        label: 'Detail',
        category: ReportCategory.COST_CHANGES,
        subCategory: CostChangesReport.DETAIL
      }
    ]
  },
  {
    label: 'Registry Data',
    category: ReportCategory.REGISTRY_DATA,
    items: [
      {
        label: 'Holding Information',
        category: ReportCategory.REGISTRY_DATA,
        subCategory: RegistryDataReport.HOLDING
      },
      {
        label: 'Share Registry Quantity Only Reconciliation',
        category: ReportCategory.REGISTRY_DATA,
        subCategory: RegistryDataReport.REGISTRY_QTY_RECON
      }
    ]
  },
  {
    label: 'Base Reports',
    category: ReportCategory.BASE_REPORT,
    items: [
      {
        label: 'Unrealised Report (No Netoff)',
        category: ReportCategory.BASE_REPORT,
        subCategory: BaseReport.UNREALISED
      },
      {
        label: 'Realised Report (No Netoff)',
        category: ReportCategory.BASE_REPORT,
        subCategory: BaseReport.REALISED
      },
      {
        label: 'Unrealised Report (Netoff)',
        category: ReportCategory.BASE_REPORT,
        subCategory: BaseReport.UNREALISED_NET_OFF
      },
      {
        label: 'Realised Report (Netoff)',
        category: ReportCategory.BASE_REPORT,
        subCategory: BaseReport.REALISED_NET_OFF
      },
      {
        label: 'Unrealised Combined Report (No Netoff)',
        category: ReportCategory.BASE_REPORT,
        subCategory: BaseReport.UNREALISED_COMBINED
      },
      {
        label: 'Income Report By Tax Year',
        category: ReportCategory.BASE_REPORT,
        subCategory: BaseReport.INCOME_BY_TAX_YEAR
      }
    ]
  }
];

const getReportTitles = () => {
  const titleObj: Record<string, string> = {};
  reportMenuItems.forEach((catItem) => {
    catItem.items.forEach(({ label, category, subCategory }) => {
      titleObj[`${category}_${subCategory}`] = label;
    });
  });
  return titleObj;
};

export const reportMenuTitles = getReportTitles();
